import React from "react";
import "../comingSoon/comingSoon.css";

const ComingSoonComponent = () => {
  return (
    <div className="container">
    <h3>COMING SOON</h3>
    <div className="bar">
      <div className="progress"></div>
    </div>
  </div>
  );
};

export default ComingSoonComponent;
