import emailjs from "emailjs-com";
export const sendContactMail =async(data)=> await emailjs.send(
    "service_bq1f7qi",
    "template_ycr0e7u",
    data,
    "35JIdQtUBW4uBv-hX"
  );
export const sendHTTMail =async(data)=> await emailjs.send(
    "service_bq1f7qi",
    "template_ycr0e7u",
    data,
    "35JIdQtUBW4uBv-hX"
  );
export const sendIndustriesMail =async(data)=> await emailjs.send(
    "service_bq1f7qi",
    "template_ycr0e7u",
    data,
    "35JIdQtUBW4uBv-hX"
  );
